<template>
  <BaseModal name="leads-filters" size="lg" :title="$t('seller.leads.text_2484')" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <div class="top-filter">
          <div>
            <p>{{ $t('seller.leads.text_279') }}</p>
          </div>
          <BaseButton
            variant="link-info"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            >{{ $t('seller.leads.text_2486') }}</BaseButton
          >
        </div>
        <b-form novalidate>
          <b-row>
            <!-- Offers -->
            <b-col cols="12" v-if="isHeaven">
              <b-form-group
                :label="$t('seller.leads.text_offers_1')"
                label-for="offers"
                class="form-relative"
              >
                <multiselect
                  id="ajax"
                  v-model="filter.offers"
                  :placeholder="$t('seller.leads.text_offers_3')"
                  label="name"
                  track-by="id"
                  :options="offers_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  :selectLabel="$t('seller.leads.text_offers_4')"
                  :deselectLabel="$t('seller.leads.text_offers_5')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :options-limit="15"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="{'heaven': isHeaven}"
                  @search-change="aux_offers_debounce"
                >
                  <template v-slot:noResult>
                    <span>{{ $t('seller.leads.text_offers_6') }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
            <!-- Country -->
            <b-col cols="12" v-if="isHeaven">
              <b-form-group
                :label="$t('seller.sales.text_country_1')"
                label-for="country"
                class="form-relative"
              >
                <multiselect
                  v-model="filter.country"
                  :placeholder="$t('seller.sales.text_country_3')"
                  label="name"
                  track-by="id"
                  :options="country_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_country_4')"
                  :deselectLabel="$t('seller.sales.text_country_5')"
                  selectedLabel="✔️"
                  :class="{'heaven': isHeaven}"
                >
                  <template v-slot:noResult>
                    <span>{{ $t('seller.sales.text_country_6') }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
            <!-- Products -->
            <b-col cols="12">
              <b-form-group :label="$t('seller.leads.text_2484')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.leads.text_2488')"
                  :selectLabel="$t('seller.leads.text_2489')"
                  :deselectLabel="$t('seller.leads.text_2490')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="{'heaven': isHeaven}"
                  @search-change="aux_product_debounce"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t('seller.leads.text_2488') }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t('seller.leads.text_280') }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.leads.text_2493') }}
      </BaseButton>
      <BaseButton variant="primary" :class="{'heaven': isHeaven}" @click="onSubmit"> {{ $t('seller.leads.text_2485') }} </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect"
import _ from "lodash"

import ProductService from "@/services/resources/ProductService"
import RecurrenceSubscriptionService from "@/services/resources/RecurrenceSubscriptionService"
import SaleService from "@/services/resources/SaleService"
import AccountStatementsService from "@/services/resources/AccountStatementsService";
import CountryService from "@/services/resources/CountryService"
import OffersService from "@/services/resources/OffersService"

const serviceProduct = ProductService.build()
const serviceRecurrenceSubscription = RecurrenceSubscriptionService.build()
const serviceSale = SaleService.build()
const serviceCountry = CountryService.build()
const serviceOffers = OffersService.build()
const serviceStatement = AccountStatementsService.build();

export default {
  name: "FilterLeads",
  props: {
    value: {
      type: Number
    }
  },
  components: { Multiselect },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: "",
      loading: false,
      loading_contract: false,
      affiliates: [],
      filter: {
        affiliates: [],
        status: [
        ],
        method: [
        ],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        country: [],
        offers: []
      },
      products: [],
      contracts: [],
      method_list: [
      ],
      country_list: [],
      offers_list: [],
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true;
      this.search = query;
      this.debounce_contract();
    },
    debounce_contract: _.debounce(function () {
      this.fetchContract(this.search);
    }, 500),

    // offers
    aux_offers_debounce(query) {
      this.loading = true
      this.search = query
      setTimeout(() => {
        this.fetchOffers(this.search)
      }, 500)
    },
    debounce_offers: _.debounce(function () {
      this.fetchContract(this.search);
    }, 500),

    onSubmit() {
      let data = {}

      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }

      if (this.filter.country.length) {
        data.country = this.filter.country.map((item) => item.country_code);
      }

      if (this.filter.offers.length) {
        data.offers = this.filter.offers.map((item) => item.id);
      }

      // if (this.filter.contracts.length) {
      //   data.contracts = this.filter.contracts.map((item) => item.id);
      // }

      localStorage.setItem('filter_leads', JSON.stringify(this.filter))

      this.$emit("filter", data)
      this.$bvModal.hide("leads-filters")
    },
    fetchProducts(search = null) {
      let data = { list: true }

      if (search) data.search = search

      this.products = []

      serviceProduct
        .search(data)
        .then(response => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name })
          })
        })
        .catch(err => {
          console.error(err);
          this.products = [];
        })
        .finally(() => { this.loading = false })
    },
    fetchContract(search = null) {
      let data = {
        id: search,
      };

      this.contracts = [];

      serviceRecurrenceSubscription
        .read(data)
        .then((response) => {
          if (response.data) {
            response.data.data.forEach((element) => {
              this.contracts.push({
                id: element.id,
                name: `${element.contract.live.product.name} | ${element.client.name} `,
              });
            });
          } else {
            this.contracts.push({
              id: response.id,
              name: `${response.contract.live.product.name} | ${response.client.name} `,
            });
          }
        })
        .catch((err) => {
          this.contracts = [];
          console.log(err);
        })
        .finally(() => {
          this.loading_contract = false;
        });
    },
    fetchAffiliates() {
      let data = {
        id: "/affiliate",
      };

      this.affiliates = [];

      serviceSale
        .read(data)
        .then((response) => {
          response.forEach((element) => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.affiliates = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCountry() {
      serviceCountry
        .read("/list")
        .then(res => { this.country_list = res })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    fetchOffers(search = null) {
      let data = {}
      if (search) data = { search }
      serviceOffers.search(data)
        .then(res => { this.offers_list = res })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    limparLocal() {
      window.localStorage.removeItem("sales_filters_aux")
      window.localStorage.removeItem("sales_filters")
      window.localStorage.removeItem("filter_leads")
      this.filter = {
        status: [
          {
            id: "paid",
            name: "Pago"
          },
          {
            id: "waiting_payment",
            name: "Aguardando pagamento"
          }
        ],
        method: [
          { id: "CREDIT_CARD", name: "Cartão de crédito" },
          { id: "BOLETO", name: "Boleto" },
          { id: "PIX", name: "Pix" },
          { id: "PAYPAL", name: "PayPal" },
          { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" }
        ],
        country: [],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        affiliates: [],
        offers: []
      }
      this.products = []
      this.contracts = []
      this.affiliates = []
      this.verificaLocal()
    },
    verificaLocal() {
      if (window.localStorage.sales_filters_aux) {
        this.contemFilterLocal = true
      } else {
        this.contemFilterLocal = false
      }
    },
    openModal() {
      this.verificaLocal()
      if (window.localStorage.sales_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.sales_filters_aux)
        this.filter = this.pre_filter
      }
      if(JSON.parse(localStorage.getItem('filter_leads'))) {
        this.filter = JSON.parse(localStorage.getItem('filter_leads'))
      }
    }
  },
  mounted(){
    // this.fetchAffiliates();
  },
  created() {
    // this.fetchProducts();
    this.fetchOffers()
    this.fetchCountry()
    if(JSON.parse(localStorage.getItem('filter_leads'))) {
      this.filter = JSON.parse(localStorage.getItem('filter_leads'))
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: var(--gray-200);
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
